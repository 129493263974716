import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";

const API_URL = "google";

class GoogleService {
  public static login(): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/login/url");
  }

  public static postLogin(authCode: string): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/auth/login?auth_code=" + authCode, {});
  }
}

export default GoogleService;
